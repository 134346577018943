import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Card, Col, Form, Input, message, Row } from "antd";
import { API } from "../../constant";
import '../../App.css';

const PasswordRecovery = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [step, setStep] = useState(1); 
    const [token, setToken] = useState("");
    const [form] = Form.useForm(); 

    const handleRequestToken = async (values) => {
        setIsLoading(true);
        try {
            const requestData = {
                data: {
                    email: values.email
                }
            };

            const req = await fetch(`${API}/client/auth/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
            const data = await req.json();
            if(data.success === false) return setError(error?.message ?? "Error locating email on server.");
            
            message.success('E-mail sent!');
            setStep(2);
        } catch (error) {
            console.error(error);
            setError(error?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetPassword = async (values) => {
        setIsLoading(true);
        try {
            const requestData = {
                token: values.token,
                password: values.password
            };

            const response = await fetch(`${API}/client/auth/set-password/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error("Erro ao definir a nova senha.");
            }

            message.success("Senha atualizada com sucesso!");
            history.push('/');

        } catch (error) {
            console.error(error);
            setError(error?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    const compareToFirstPassword = (_, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('As senhas não coincidem.'));
        } else {
            return Promise.resolve();
        }
    };

    return (
        <Fragment>
            <div className="login-container">
                <Row align="middle" className="login-row">
                    <Col span={8} offset={8}>
                        <Card title={step === 1 ? "Password recovery" : "Set a new password"}>
                            {error && <Alert className="alert_error" message={error} type="error" closable />}
                            {step === 1 && (
                                <Form form={form} name="requestTokenForm" layout="vertical" onFinish={handleRequestToken} autoComplete="off">
                                    <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please, enter a valid email." }]}>
                                        <Input placeholder="Email address" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                                    </Form.Item>
                                </Form>
                            )}
                            {step === 2 && (
                                <Form form={form} name="setPasswordForm" layout="vertical" onFinish={handleSetPassword} autoComplete="off">
                                    <Form.Item label="Token" name="token" rules={[{ required: true, message: "Please, enter a valid token." }]}>
                                        <Input placeholder="Password recovery token" onChange={(e) => setToken(e.target.value)} />
                                    </Form.Item>
                                    <Form.Item label="Nova senha" name="password" rules={[{ required: true, message: "Please, enter your new password." }]}>
                                        <Input.Password placeholder="Your new password" />
                                    </Form.Item>
                                    <Form.Item label="Confirm your new password" name="verify_password" dependencies={['password']} rules={[{ required: true, message: "Please, confirm your new password" }, { validator: compareToFirstPassword }]}>
                                        <Input.Password placeholder="Confirm your new password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

export default PasswordRecovery;
