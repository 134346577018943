import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
    HomeOutlined,
    HistoryOutlined,
    FileTextOutlined,
    SettingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
// import logo from '../../logo.svg';
import darkLogo from '../../ai_logo_dark.svg';

const { Sider } = Layout;

const navigation = [
    { id: 1, label: "Home", target: "/dashboard" },
    { id: 2, label: "Conversations", target: "/dashboard/conversations" },
    { id: 3, label: "Reports", target: "/dashboard/reports" },
    { id: 4, label: "Configurations", target: "/dashboard/configs" },
    { id: 5, label: "LOGOUT", target: "/logout", danger: true },
];

const SideBar = ({ onCollapse, collapsed }) => {
    const history = useHistory();
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const handleCollapse = () => {
        onCollapse(!collapsed);
    };

    useEffect(() => {
        const path = location.pathname;
        const menuItem = navigation.find(item => item.target === path);
        if (menuItem) {
            setSelectedMenuItem(menuItem.id.toString());
        }
    }, [location]);

    const handleMenuClick = ({ key }) => {
        const { target } = navigation.find(item => item.id === parseInt(key)) || {};
        history.push(target)
    }

    const getIcon = (label) => {
        switch(label) {
            case 'Home':
                return <HomeOutlined />;
            case 'Conversations':
                return <HistoryOutlined />;
            case 'Reports':
                return <FileTextOutlined />;
            case 'Configurations':
                return <SettingOutlined />;
            case 'LOGOUT':
                return <LogoutOutlined />;
            default:
                return null;
        }
    }

    const siderStyle = {
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        width: collapsed ? 80 : 200,
    };

    return (
        <Sider breakpoint='md' style={siderStyle} onCollapse={handleCollapse} collapsed={collapsed}>
            <div className="logo" style={{ margin: '20px', textAlign: 'center' }}>
                <img src={darkLogo} alt="Logo" style={{ maxWidth: '100%' }} />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[selectedMenuItem]}
                onClick={handleMenuClick}
                style={{ height: '100%', borderRight: 0, overflowY: 'auto' }}
            >
                {navigation.map(item => (
                    <Menu.Item key={item.id} icon={getIcon(item.label)} danger={item.danger} >
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
}

export default SideBar;
