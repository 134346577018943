import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { API } from '../../../constant';
import { getUser, getToken } from '../../../helpers';

const BarChartQuestions = ({ filters, setTotalQuestions }) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalQuestions, setLocalTotalQuestions] = useState(0);
    const [hasFilter, setHasFilter] = useState(false);
  
    useEffect(() => {
      const fetchData = async () => {
        const user = getUser();
        const token = getToken();
        try {
          let url = `${API}/log/lastWeekQuestions/${user.id}`;
          if (filters) {
            const { dates } = filters;
            if (dates && dates.length === 2) {
              url += `?startDate=${encodeURIComponent(dates[0])}&endDate=${encodeURIComponent(dates[1])}`;
              setHasFilter(true);
            } else {
              const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
              const endDate = moment().format('YYYY-MM-DD');
              url += `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
              setHasFilter(false);
            }
          } else {
            const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
            const endDate = moment().format('YYYY-MM-DD');
            url += `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
            setHasFilter(false);
          }
  
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Bearer: token
            }
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
  
          const data = await response.json();
          setData(data.results);
          if (setTotalQuestions) {
            setTotalQuestions(data.totalQuestionCount);
            setLocalTotalQuestions(data.totalQuestionCount);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
    fetchData();
  }, [history, filters, setTotalQuestions]);

  const graphStructure = (conversations) => {
    return conversations.map(entry => ({
      day: entry.date ? entry.date.slice(0, 10) : '',
      Questions: entry.questionCount
    }));
  };

  const chartData = graphStructure(data);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>An error occurred: {error}</p>;
  }

  const title = hasFilter ? 'Questions per day' : 'Questions in the last week';

  return (
    <Card style={{ color: '#000000' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <h4 style={{marginTop: 0}}>{title}</h4>
          <span><strong style={{ fontSize: '11px' }}>Total: {totalQuestions} questions</strong></span>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Questions" fill="#1890ff" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'right', marginTop: '12px' }}>
          <a href="/dashboard/conversations" style={{ color: '#1890ff' }}>See more</a>
        </div>
      </div>
    </Card>
  );
};

export default BarChartQuestions;