import React from "react";
import AppRoutes from "./Routes";
import './App.css';
    

import { ConfigProvider, Layout } from "antd";
import theme from './components/Theme/Theme';
const { Content } = Layout;

const App = () => {
  return (
    <Layout hasSider>
        <ConfigProvider theme={theme}>
          <AppRoutes />
        </ConfigProvider>
    </Layout>
  );
};
    
export default App;