import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import "leaflet.markercluster";

const bounds = [
  [-90, -200],
  [90, 200]
];

const MapWithMarkerCluster = React.memo(({ data, onMarkerClick, onMapInteraction, isFiltered }) => {
  return (
    <MapContainer 
      center={[20, 0]}
      zoom={2}
      minZoom={2}
      maxBounds={bounds}
      style={{ width: '100%', height: '500px' }}
      maxBoundsViscosity={1.0}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='© OpenStreetMap contributors'
      />
      <MarkerCluster data={data} onMarkerClick={onMarkerClick} onMapInteraction={onMapInteraction} isFiltered={isFiltered} />
    </MapContainer>
  );
});

const MarkerCluster = ({ data, onMarkerClick, onMapInteraction, isFiltered }) => {
  const markersGroupRef = useRef(null);

  const map = useMapEvents({
    click: () => {
      if (onMapInteraction && !isFiltered) {
        onMapInteraction();
      }
    },
    dragend: () => {
      if (onMapInteraction && !isFiltered) {
        onMapInteraction();
      }
    }
  });

  useEffect(() => {
    if (!markersGroupRef.current) {
      markersGroupRef.current = L.markerClusterGroup();
      map.addLayer(markersGroupRef.current);
    }

    markersGroupRef.current.clearLayers();

    data.forEach(item => {
      const marker = L.marker([item.lat, item.lng], {
        icon: L.icon({
          iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
          shadowSize: [41, 41]
        })
      });

      marker.bindPopup(item.interaction_question);
      marker.on('click', () => { onMarkerClick(item.interaction_uuid); });
      marker.on('mouseover', function (e) {
        marker.openPopup();
      });
      marker.on('mouseout', function (e) {
        marker.closePopup();
      });

      markersGroupRef.current.addLayer(marker);
    });

  }, [data, map]);

  return null;
};

export default MapWithMarkerCluster;