import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, message } from 'antd';
import MapWithMarkerCluster from "../../components/BubbleMap/BubbleMap";
import TopRanking from "../../components/TopRanking/TopRanking";
import FilterConversation from "../HistoryConversation/FilterConversation";
import { API } from "../../constant";
import { getToken, getUser } from "../../helpers";

const Reports = () => {
    const history = useHistory();
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [error, setError] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);

    const getAll = async () => {
        try {
            const token = getToken();
            const user = getUser();
            if (!token || !user) return history.push('/');
    
            const url = `${API}/log/getAll/${user.id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Bearer: token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setAllData(data.data);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        getAll();
    }, []);

    useEffect(() => {
        if (error) {
            message.info('Your session has expired or an unexpected error occurred. Please, log in again.');
            history.replace('/');
        }
    }, [error, history]);

    const groupedConversations = useMemo(() => {
        return allData.reduce((acc, conversation) => {
            const existingGroup = acc.find(group => group.uuid === conversation.uuid);
            const newQuestion = {
                question: conversation.question,
                answer: conversation.answer,
                type: conversation.type,
                ipAddress: conversation.ipAddress,
                time: conversation.createdAt,
                geoip_latlong: conversation.geoip_latlong,
                report: conversation.report
            };
            if (existingGroup) {
                existingGroup.questions.push(newQuestion);
            } else {
                acc.push({
                    uuid: conversation.uuid,
                    questions: [newQuestion]
                });
            }
            return acc;
        }, []);
    }, [allData]);

    const formatDateTime = (isoDateTime) => {
        const date = new Date(isoDateTime);
        return `${date}`;
    };

    const handleMarkerClick = useCallback((uuid) => {
        const selectedSession = groupedConversations.find(group => group.uuid === uuid);
        if (selectedSession) {
            setSelectedSession(selectedSession);
        }
    }, [groupedConversations]);
    
    const handleMapInteraction = useCallback(() => {
        setFilteredData([]);
        setIsFiltered(false);
        setSelectedSession(null);
    }, []);

    const handleFilter = (filters) => {
        const dates = Array.isArray(filters.dates) ? filters.dates : [];
    
        if (filters.keyword || dates.length > 0) {
            const filtered = allData.filter(conversation => {
                const matchesKeyword = filters.keyword ? conversation.question.includes(filters.keyword) : true;
                const matchesDate = dates.length > 0 ? dates.includes(conversation.createdAt.split('T')[0]) : true;
                return matchesKeyword && matchesDate;
            });
    
            const filteredGrouped = groupedConversations.filter(group =>
                group.questions.some(question => filtered.some(f => f.uuid === group.uuid))
            );
            setFilteredData(filteredGrouped);
            setIsFiltered(true);
            setSelectedSession(null);
        } else {
            setFilteredData([]);
            setIsFiltered(false);
            setSelectedSession(null);
        }
    };    

    const dataToDisplay = useMemo(() => {
        const sourceData = isFiltered ? filteredData : groupedConversations;
        return sourceData.map(group => {
            const question = group.questions[0];
            return {
                lat: parseFloat(question.geoip_latlong.split(',')[0]),
                lng: parseFloat(question.geoip_latlong.split(',')[1]),
                interaction_question: question.question,
                interaction_uuid: group.uuid
            };
        });
    }, [isFiltered, filteredData, groupedConversations]);

    const memoizedMap = useMemo(() => (
        <MapWithMarkerCluster
            data={dataToDisplay}
            onMarkerClick={handleMarkerClick}
            onMapInteraction={handleMapInteraction}
            isFiltered={isFiltered}
        />
    ), [dataToDisplay, handleMarkerClick, handleMapInteraction, isFiltered]);    
    
    return (
        <>
        <FilterConversation onFilter={handleFilter} />
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card style={{ height: '550px' }}>
                    {memoizedMap}
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card style={{ color: '#000000' }}>
                    <div style={{ padding: '16px 24px' }}>
                        {selectedSession ? (
                            <div style={{ marginBottom: '24px', border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                                <div>
                                    <h3>Session: {selectedSession.uuid}</h3>
                                </div>
                                {selectedSession.questions.map((question, idx) => (
                                    <div key={idx} style={{ marginBottom: '8px' }}>
                                        <strong>Question:</strong> {question.question}<br />
                                        <strong>Answer:</strong> {question.answer}<br />
                                        <strong>Type Interaction:</strong> {question.type}<br />
                                        <strong>IP Address:</strong> {question.ipAddress}<br />
                                        <strong>Time:</strong> {formatDateTime(question.time)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <TopRanking />
                        )}
                    </div>
                </Card>
            </Col>
        </Row>
        </>
    );
};

export default Reports;