import { useEffect, useState } from 'react';
import { API } from '../../constant';
import { getToken, getUser } from '../../helpers';
import { Card, Spin, Col } from 'antd';

const PaymentStatus = () => {
  const [isPaymentUpToDate, setIsPaymentUpToDate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = getUser();
      const token = getToken();
      try {
        const response = await fetch(`${API}/client/${user.id}`, {
          method: "GET",
          headers: {
            Bearer: token
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        setIsPaymentUpToDate(data.data.Financially_regular);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return (
      <Col span={24}>
        <Card style={{ color: 'red', padding: '10px', border: '1px solid red', borderRadius: '5px' }}>
          <strong>Error:</strong> {error}
        </Card>
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Card style={{ color: isPaymentUpToDate ? '#000000' : 'red', padding: '10px', border: isPaymentUpToDate ? 'none' : '1px solid red', borderRadius: '5px' }}>
        <strong>{isPaymentUpToDate ? 'Financial status:' : 'Attention:'}</strong> {isPaymentUpToDate ? 'Payment up to date' : 'Your payment status is not up to date.'}
      </Card>
    </Col>
  );
};

export default PaymentStatus;