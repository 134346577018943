import React, { useState, useEffect } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Form, Input, Upload, Button, InputNumber, Select, message, Modal } from 'antd';
import { API } from "../../constant";
import { getToken, getUser } from "../../helpers";
import { useHistory } from "react-router-dom";
const { Option } = Select;

const UserConfig = () => {
    const history = useHistory();
    const [userData, setUserData] = useState(null);
    const [chatbotData, setChatBotData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedVoiceSrc, setSelectedVoiceSrc] = useState("");
    const [selectedVoice, setSelectedVoice] = useState("");
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [submittingCancel, setSubmittingCancel] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isValidFile, setIsValidFile] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [messageFile, setMessageFile] = useState("Submit a new document to train your assistant");
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = getToken();
                const user = getUser();
                if(!token || !user) return history.push('/');
                const response = await fetch(`${API}/client/${user.id}`, {
                    method: "GET",
                    headers: {
                        Bearer: token
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setUserData(data);
                setChatBotData(data.dataChatBot);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [history]);

    useEffect(() => {
        if (selectedVoice && selectedVoice !== 'none') {
            setSelectedVoiceSrc(`https://cdn.openai.com/API/docs/audio/${selectedVoice}.wav`);
        } else {
            setSelectedVoiceSrc('');
        }
    }, [selectedVoice]);

    useEffect(() => {
        if (successMessage) {
            const timeout = setTimeout(() => {
                window.location.reload();
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [successMessage]);

    useEffect(() => {
        if (error) {
            message.info('Your session has expired or an unexpected error occurred. Please, log in again.');
            history.replace('/');
        }
    }, [error, history]);

    const normFile = (e) => {
        return e && Array.isArray(e.fileList) ? e.fileList : [];
    };

    const checkFile = (file) => {
        const fileName = file.name.toLowerCase();
        const acceptedFormats = ['.docx', '.pdf', '.txt'];
        const acceptedFileSize = file.size <= 25000000;
        const isAccepted = acceptedFormats.some(format => fileName.endsWith(format));
        setIsValidFile(isAccepted);
        if(!isAccepted) {
            message.error('File format not supported!');
            setMessageFile("Please, choose supported files (.docx, .pdf or .txt)");
        }
        if(!acceptedFileSize) {
            message.error('File size not supported!');
            setMessageFile("File size cannot exceed 25MB");
        }
        return isAccepted;
    }    

    const applyChanges = async () => {
        try {
            setSubmitting(true);
            const user = getUser();
            const fields = await form.validateFields();
    
            const isAnyFieldFilled = Object.values(fields).some(value => value !== "" && value !== undefined);
    
            if (!isAnyFieldFilled && (!fields.upload || fields.upload.length === 0)) {
                throw new Error('At least one field is required.');
            }
    
            const formData = new FormData();
            formData.append('userId', user.id);
            formData.append('System_prompt', fields.system_prompt || "");
            formData.append('Max_questions', fields['input-questions'] || "");
            formData.append('Max_answer_lenght', fields['input-lenght'] || "");
            formData.append('Voice', selectedVoice || "");
            
            if (fields.upload && fields.upload.length > 0) {
                const file = fields.upload[0].originFileObj;
                formData.append('files', file, file.name);
            }
    
            const response = await fetch(`${API}/client/auth/apply-changes`, {
                method: "POST",
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Failed to apply changes');
            }
    
            setSuccessMessage(true);
            message.success('Request sent successfully.');
        } catch (error) {
            message.error(error.message || 'Failed to apply changes. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    };    

    if (loading) {
        return null;
    }

    const handleChange = (info) => {
        const fileStatus = info.file.status;
    
        if (fileStatus === 'removed') {
            const remainingFiles = info.fileList.filter(file => file.status === 'uploading');
            if (remainingFiles.length === 0) {
                const unsupportedFiles = info.fileList.some(file => !checkFile(file));
                if (!unsupportedFiles) {
                    setIsValidFile(true);
                    setUploading(false);
                    setMessageFile("Submit a new document to train your assistant");
                }
            }
        } else {
            setUploading(true);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleCancel = async () => {
        setSubmittingCancel(true);
        const user = getUser();

        try {
            const response = await fetch(`${API}/client/cancelSubscription`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: user.id })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setIsModalVisible(false);
            message.success('Successfully');
        } catch (error) {
            message.error('An error occurred when reporting the interaction. Try again');
        } finally {
            setSubmittingCancel(false);
        }
    };

    const handleNevermind = () => {
        setIsModalVisible(false);
    };

    return (
        <Row gutter={[16, 16]}>
            <Col md={24} lg={12}>
                <Card style={{ color: '#000000' }}>
                    <div style={{ padding: '16px 24px' }}>
                        <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <h2 style={{ marginBottom: '16px' }}>Suggest Changes</h2>
                            <div style={{ marginBottom: '16px' }}>
                                <Form.Item
                                    label="System Prompt"
                                    name="system_prompt"
                                >
                                    <Input placeholder="Prompt that determines AI actions" />
                                </Form.Item> 
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                                <div>
                                    <Form.Item label="Max Questions">
                                        <Form.Item name="input-questions" noStyle>
                                            <InputNumber min={1} max={30} disabled />
                                        </Form.Item>
                                        <span className="ant-form-text" style={{ marginLeft: 16 }}>
                                            questions
                                        </span>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item label="Answer Length">
                                        <Form.Item name="input-length" noStyle>
                                            <InputNumber min={1} max={30} />
                                        </Form.Item>
                                        <span className="ant-form-text" style={{ marginLeft: 16 }}>
                                            words
                                        </span>
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <Form.Item
                                    name="upload"
                                    label="Knowledge Base"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    extra={messageFile}
                                    >
                                    <Upload
                                        name="document"
                                        listType="picture"
                                        beforeUpload={checkFile}
                                        maxCount={1}
                                        onChange={handleChange} >
                                            <Button icon={<UploadOutlined />} disabled={!isValidFile || uploading}>
                                                Click to upload
                                            </Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <Form.Item
                                    name="select"
                                    label="Voice"
                                    hasFeedback
                                >
                                    <Select placeholder="Choose your assistant's voice" onChange={(value) => {setSelectedVoice(value);}}>
                                        <Option value="alloy">Alloy</Option>
                                        <Option value="echo">Echo</Option>
                                        <Option value="fable">Fable</Option>
                                        <Option value="onyx">Onyx</Option>
                                        <Option value="nova">Nova</Option>
                                        <Option value="shimmer">Shimmer</Option>
                                        <Option value="">Remove choice</Option>
                                    </Select>
                                    <div key={selectedVoiceSrc} style={{ marginTop: "15px" }}>
                                        {selectedVoiceSrc && (
                                            <audio controls>
                                                <source src={selectedVoiceSrc} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                            </audio>
                                        )}
                                    </div>
                                </Form.Item>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="apply_changes_btn"
                                        onClick={applyChanges}
                                        loading={submitting}
                                        disabled={!isValidFile}
                                    >
                                        Send to us
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Card>
            </Col>
            <Col md={24} lg={12}>
                <Card style={{ color: '#000000' }}>
                    <div style={{ padding: '16px 24px' }}>
                        <h2 style={{ marginBottom: '16px' }}>Current Settings</h2>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Company Name:</strong> {userData && userData.data.Client_name}
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Email:</strong> {userData && userData.data.email}
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>System Prompt:</strong> {chatbotData && chatbotData.System_prompt}
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Questions per conversation:</strong> {chatbotData && chatbotData.MaxQuestions}
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Answer Length: ~</strong> {chatbotData && chatbotData.MaxAnswerLength} words
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Chosen Voice:</strong> {userData && userData.data.Voice}
                        </div>
                        <div style={{ marginBottom: '16px', display: 'grid', alignItems: 'center', justifyContent: 'end' }}>
                            <Button type="default" danger style={{ fontSize: '14px' }} onClick={showModal}>Cancel your subscription</Button>
                            <Modal
                                title="Do you really want to cancel your subscription?"
                                open={isModalVisible}
                                onOk={handleCancel}
                                okType="danger"
                                cancelButtonProps={{ type: "primary" }}
                                okButtonProps={{ loading: submittingCancel }}
                                onCancel={handleNevermind}
                                okText="Send request"
                                cancelText="Nevermind"
                            >
                                <p>
                                    Before proceeding, please be aware that your request will still be sent to us, and your service will remain active until we can review it.
                                </p>
                            </Modal>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default UserConfig;
