import React, { useState } from 'react';
import { Input, DatePicker, Row, Button, Col } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const FilterConversation = ({ onFilter }) => {
    const [keyword, setKeyword] = useState('');
    const [selectedDates, setSelectedDates] = useState([]);

    const defaultStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD');

    const handleSearch = () => {
        const filters = {
            keyword,
            dates: selectedDates.length ? selectedDates.map(date => date.format('YYYY-MM-DD')) : []
        };
        onFilter(filters);
    };

    const handleDateChange = (dates) => {
        if (!dates || dates.length === 0) {
            handleClearFilters();
        } else {
            setSelectedDates(dates);
        }
    };

    const handleClearFilters = () => {
        setKeyword('');
        setSelectedDates([]);
        onFilter({});
    };

    return (
        <div>
            <strong>Filter Search</strong>
            <Row gutter={16} style={{ marginTop: 10 }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Input.Search
                        placeholder="Keyword or IP Address"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        onSearch={handleSearch}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <RangePicker
                        style={{ marginBottom: 10, width: '100%' }}
                        onChange={handleDateChange}
                        value={selectedDates.length ? selectedDates : null}
                        format="YYYY-MM-DD"
                        placeholder={[defaultStartDate, defaultEndDate]}
                    />
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                    <Button type="primary" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClearFilters} style={{ marginLeft: 8 }}>Clear Filters</Button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterConversation;