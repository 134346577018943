import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../constant";
import { getToken, getUser } from "../../helpers";
import FilterConversation from "./FilterConversation";
import BarChartQuestions from "../../components/Charts/Question/Question";
import { Row, Col, Card, Modal, Input, Button, message, Pagination } from 'antd';

const HistoryConversation = () => {
    const history = useHistory();
    const [logs, setLogs] = useState([]);
    const [meta, setMeta] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedSession, setExpandedSession] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [showFilteredResults, setShowFilteredResults] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedSessionUuid, setSelectedSessionUuid] = useState(null);
    const [reportReason, setReportReason] = useState('');
    const [reports, setReports] = useState({});
    const [totalQuestions, setTotalQuestions] = useState(0);
    const conversationsPerPage = 10;

    const fetchData = async (page) => {
        setLoading(true);
        try {
            const token = getToken();
            const user = getUser();
            if (!token || !user) return history.push('/');
            
            let url = `${API}/log/getConversations/${user.id}?page=${page}&pageSize=${conversationsPerPage}`;
            let hasActiveFilters = false;
            if (filteredData) {
                const { keyword, dates } = filteredData;
                if (keyword) {
                    url += `&keyword=${encodeURIComponent(keyword)}`;
                    hasActiveFilters = true;
                }
                if (dates && dates.length === 2) {
                    url += `&startDate=${encodeURIComponent(dates[0])}&endDate=${encodeURIComponent(dates[1])}`;
                    hasActiveFilters = true;
                }
            }
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Bearer: token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setLogs(data.data);
            setMeta(data.meta);
            setShowFilteredResults(hasActiveFilters);

            const reportsData = {};
            data.data.forEach(conversation => {
                conversation.questions.forEach(question => {
                    reportsData[conversation.uuid] = question.report;
                });
            });
            setReports(reportsData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };     

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, filteredData]);

    useEffect(() => {
        if (error) {
            message.info('Your session has expired or an unexpected error occurred. Please, log in again.');
            history.replace('/');
        }
    }, [error, history]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber !== currentPage) {
            setCurrentPage(pageNumber);
            window.scrollTo(0,0);
        }
    };

    const handleSessionClick = (uuid) => {
        if (expandedSession === uuid) {
            setExpandedSession(null);
        } else {
            setExpandedSession(uuid);
        }
    };

    const handleFilter = (filters) => {
        if (!filters.keyword && (!filters.dates || filters.dates.length === 0)) {
            setFilteredData(null);
            setShowFilteredResults(false);
        } else {
            setFilteredData(filters);
            setShowFilteredResults(true);
        }
        setCurrentPage(1);
    };

    const handleReportInteraction = (uuid) => {
        setSelectedSessionUuid(uuid);
        setModalVisible(true);
    };

    const handleConfirmReport = async () => {
        const user = getUser();
        const token = getToken();

        try {
            const response = await fetch(`${API}/report/newReport/${user.id}`, {
                method: "POST",
                headers: {
                    Bearer: token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ reason: reportReason, sessionId: selectedSessionUuid })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setModalVisible(false);
            setReportReason('');
            message.success('Interaction reported successfully');
        } catch (error) {
            message.error('An error occurred when reporting the interaction. Try again');
        }
    };
    
    const handleCancelModal = () => {
        setModalVisible(false);
        setReportReason('');
    };

    const countChar = (txt) => Array.from(txt).length;

    const handleExceedFormatter = (txt, { max }) => {
        const char = Array.from(txt);
        return char.slice(0, max).join('');
    };

    const getReportText = (report) => {
        if (report === null) return "Report this interaction";
        if (report.resolved === false) return "Already reported";
        if (report.resolved === true) return "Resolved";
        return "Unknown report status";
    };

    const totalItems = meta.pagination ? meta.pagination.total : 0;

    const pageSize = meta.pagination ? meta.pagination.pageSize : 0;

    const formatDateTime = (isoDateTime) => {
        const date = new Date(isoDateTime);
        return `${date}`;
    };

    const title = showFilteredResults ? 'Your filter results' : 'Session Conversations';

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card style={{ color: '#000000' }}>
                    <FilterConversation onFilter={handleFilter} />
                </Card>
            </Col>
            <Col md={24} lg={12}>
                <BarChartQuestions 
                    filters={filteredData}
                    setTotalQuestions={setTotalQuestions}
                />
            </Col>
            <Col md={24} lg={12}>
                <Card style={{ color: '#000000' }}>
                    <h3 style={{ marginBottom: '16px', marginTop: '0' }}>{title}</h3>
                    {logs.map((session, index) => (
                        <div key={index} style={{ marginBottom: '24px', border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
                            <div style={{ cursor: 'pointer' }} onClick={() => handleSessionClick(session.uuid)}>
                                <h2 style={{marginBottom: '5px', marginTop: 0}}>{session.questions[0].question}</h2>
                                <h5 style={{margin: 0}}>{formatDateTime(session.questions[0].time)}</h5>
                                <h5 style={{marginTop: '5px', marginBottom: 0}}>Session: {session.uuid}</h5>
                            </div>
                            {expandedSession === session.uuid && (
                                <div style={{ marginTop: '8px' }}>
                                    {session.questions.map((question, idx) => (
                                        <div key={idx} style={{ marginBottom: '8px' }}>
                                            <strong>Question:</strong> {question.question}<br />
                                            <strong>Answer:</strong> {question.answer}<br />
                                            <strong>Type Interaction:</strong> {question.type}<br />
                                            <strong>IP Address:</strong> {question.ipAddress}<br />
                                            <strong>Time:</strong> {formatDateTime(question.time)}<br /><br />
                                        </div>
                                    ))}
                                    <div style={{ textAlign: 'right' }}>
                                        <a href="#" onClick={() => handleReportInteraction(session.uuid)} style={{ color: '#1890ff', fontSize: '11px' }}>
                                            {getReportText(reports[session.uuid])}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                    <div style={{ marginTop: '16px', textAlign: 'center' }}>
                        <Pagination
                            current={currentPage}
                            total={totalItems}
                            onChange={handlePageChange}
                            pageSize={pageSize}
                            responsive={true}
                            showQuickJumper={false}
                            showSizeChanger={false}
                        />
                    </div>

                    {loading && <p>Loading conversations...</p>}
                    {!loading && logs.length === 0 && <p>No conversations found.</p>}   
                </Card>
                <Modal
                    title={"Report interaction: " + (selectedSessionUuid ? selectedSessionUuid : '')}
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    destroyOnClose
                    footer={[
                        <div style={{ marginTop: '30px' }}>
                            <Button style={{ marginRight: '10px' }} onClick={handleCancelModal} danger>
                                Cancel
                            </Button>
                            <Button onClick={handleConfirmReport} type="primary">
                                Submit
                            </Button>
                        </div>
                    ]}
                >
                    <p>
                        Use the field below to report any bad behavior, including wrong answers, incomplete or outdated information.<br /><br />
                        We will try to implement a fix. Depending on the severity, we will contact you for a better solution.
                    </p>
                    <Input.TextArea
                        rows={4}
                        value={reportReason}
                        onChange={(e) => setReportReason(e.target.value)}
                        placeholder="Enter the reason for the report..."
                        count={{
                            show: true,
                            max: 2000,
                            strategy: countChar,
                            exceedFormatter: handleExceedFormatter
                        }}
                    />
                </Modal>
            </Col>
        </Row>
    );
};

export default HistoryConversation;