export const getToken = () => {
    return localStorage.getItem("AUTH_TOKEN");
}

export const setToken = (token) => {
    if(token) localStorage.setItem("AUTH_TOKEN", token);
}

export const removeToken = () => {
    localStorage.removeItem("AUTH_TOKEN");
}

export const getUser = () => {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : null;
}

export const setUser = (userId) => {
    const user = JSON.stringify({ "id": userId });
    return localStorage.setItem("user", user);
}

export const removeUser = () => {
    localStorage.removeItem("user");
}