import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import BarChartQuestions from '../../components/Charts/Question/Question';
import PieChartCosts from '../../components/Charts/Costs/Costs';
import '../../App.css';
import { getToken, getUser } from '../../helpers';
import { API } from '../../constant';
import { useHistory } from 'react-router-dom';
import PaymentStatus from '../../components/PaymentStatus/PaymentStatus';

const HomeView = () => {
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [error, setError] = useState(null);
  
  const token = getToken();
  const user = getUser();

  if(!token || !user) return history.replace('/');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}/client/${user.id}`, {
          method: "GET",
          headers: {
            Bearer: token,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setUserData(data);

      } catch (error) {
        setError(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if(error) {
      message.info('Your session has expired or an unexpected error occurred. Please, log in again.');
      return history.replace('/');
    }
  }, [error, history]);

  return (
    <Row gutter={[16, 16]}>
        <PaymentStatus />
      <Col md={24} lg={12}>
        <BarChartQuestions setTotalQuestions={setTotalQuestions} />
      </Col>
      <Col md={24} lg={12}>
        <PieChartCosts setMonthTotal={setMonthTotal} />
      </Col>
    </Row>
  );
};

export default HomeView;