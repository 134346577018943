import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const clearLocalStorage = async () => {
            try {
                localStorage.clear();
                history.replace('/');
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        clearLocalStorage();
    }, [history]);

    if (loading) {
        return null;
    }

    return null;
};

export default Logout;
