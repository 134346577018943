import React, { useEffect, useState } from 'react';
import { Card, List, Avatar, Statistic } from 'antd';
import { API } from '../../constant';
import { getToken, getUser } from '../../helpers';
import BRFlag from './images/br.png';
import USFlag from './images/us.png';
import PTFlag from './images/pt.png';
import ESFlag from './images/es.png';

const flagMap = {
  BR: BRFlag,
  US: USFlag,
  PT: PTFlag,
  ES: ESFlag
};

const TopRanking = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = getUser();
      const token = getToken();
      try {
        const response = await fetch(`${API}/log/geoip-country-counts/${user.id}`, {
          method: "GET",
          headers: {
            Bearer: token
          }
        });

        const data = await response.json();
        if (data && Array.isArray(data)) {
          const sortedData = data.sort((a, b) => b.count - a.count);
          setData(sortedData);
        }
      } catch (error) {
        setError('Failed to fetch data. Please try again later');
      }
    };

    fetchData();
  }, []);

  return (
    <Card title="Top Countries" style={{ marginBottom: 16 }}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={flagMap[item.country]} />}
              title={item.country}
              description={`${item.count} questions`}
            />
            <Statistic value={item.count} />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TopRanking;