import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import PasswordRecovery from "./pages/RecoveryPassword/PasswordRecovery";
import HomeView from "./pages/HomeView/HomeView";
import UserConfig from "./pages/UserConfig/UserConfig";
import HistoryConversation from "./pages/HistoryConversation/HistoryConversation";
import Reports from "./pages/Reports/Reports";
import Logout from "./components/Logout/Logout";
import ProtectedRoute from "./ProtectedRoute";

import { Layout } from "antd";
import SideBar from "./components/SideBar/SideBar";
const { Content, Footer } = Layout;

const AppRoutes = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Router>
      <Switch>
        <Route path="/" component={SignIn} exact />
        <Route path="/signin" component={SignIn} />
        <Route path="/recovery-password" component={PasswordRecovery} />
        <>
          <SideBar collapsed={collapsed} onCollapse={setCollapsed} />
          <Layout style={{ minHeight: '100vh', marginInlineStart: collapsed ? 80 : 200, transition: 'margin-inline-start 0.3s' }}>
              <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
                <ProtectedRoute path="/dashboard" component= {HomeView} exact/>
                <ProtectedRoute path="/dashboard/conversations" component={HistoryConversation} />
                <ProtectedRoute path="/dashboard/reports" component={Reports} />
                <ProtectedRoute path="/dashboard/configs" component={UserConfig} />
                <Route path="/logout" component={Logout} />
              </Content>
            <Footer style={{ textAlign: 'center', bottom: 0, width: '100%' }}>SuperUber ©{new Date().getFullYear()}</Footer>
          </Layout>
        </>
        </Switch>
    </Router>
  );
};

export default AppRoutes;
