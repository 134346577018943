import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Spin,
    Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import '../../App.css';
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken, setUser } from "../../helpers";

const SignIn = () => {
    const { isDesktopView } = useScreenSize();
    const history = useHistory();

    // const { setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            const value = {
                data: {
                    "email": values.email,
                    "password": values.password,
                }
            };
            const response = await fetch(`${API}/client/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();
            
            if (data?.error) {
                throw data?.error;
            }
            
            if(data.success !== true) {
                setError("Erro ao localizar seus dados no servidor!");
            } else {
                // set the token
                setToken(data.token);

                // set the user
                setUser(data.id);

                message.success('Welcome back!');
                return history.replace("/dashboard");
            }
        } catch (error) {
            console.error(error);
            setError(error?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="login-container">
                <Row align="middle" className="login-row">
                    <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
                        <Card title="SignIn" className="login-card">
                            {error ? (
                                <Alert
                                    className="alert_error"
                                    message={error}
                                    type="error"
                                    closable
                                    afterClose={() => setError("")}
                                />
                            ) : null}
                            <Form
                                name="basic"
                                layout="vertical"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email address" />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true }]}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login_submit_btn"
                                    >
                                        Login {isLoading && <Spin size="small" />}
                                    </Button>
                                </Form.Item>
                            </Form>
                            <Typography.Paragraph className="form_help_text">
                                Forgot password? <Link to="/recovery-password">Reset</Link>
                            </Typography.Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};
  
export default SignIn;
