import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { API } from '../../../constant';
import { getUser, getToken } from '../../../helpers';
import { Typography, Card, Button } from 'antd';
import '../../../App.css';

const { Text } = Typography;

const PieChartCosts = ({ setMonthTotal }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([{ name: 'Progress', value: 0 }, { name: 'Remaining', value: 100 }]);
  const [billInformation, setBillInformation] = useState([{ name: 'Entries', value: 0 }, { name: 'TotalCost', value: 0 }]);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const COLORS = ['#00C49F', '#333'];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      const user = getUser();
      const token = getToken();
      try {
        const response = await fetch(`${API}/log/monthlyCost/${user.id}?month=${selectedMonth}&year=${selectedYear}`, {
          method: "GET",
          headers: {
            Bearer: token
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        setData([
          { name: 'Progress', value: data.progress },
          { name: 'Remaining', value: data.remaining },
        ]);
        setBillInformation([
          { name: 'Entries', value: data.entries },
          { name: 'TotalCost', value: data.totalCost },
        ]);
        if (setMonthTotal) {
          setMonthTotal(data.totalCost);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, selectedYear, setMonthTotal]);

  const handlePrevMonth = () => {
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(prevYear => prevYear - 1);
    } else {
      setSelectedMonth(prevMonth => prevMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (!(selectedMonth === currentMonth && selectedYear === currentYear)) {
      if (selectedMonth === 12) {
        setSelectedMonth(1);
        setSelectedYear(prevYear => prevYear + 1);
      } else {
        setSelectedMonth(prevMonth => prevMonth + 1);
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>An error occurred: {error}</p>;
  }

  const monthTotal = billInformation[1].value.toFixed(2);
  const currentMonthName = new Date(selectedYear, selectedMonth - 1).toLocaleString('en-us', { month: 'long' });
  const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();

  return (
    <Card style={{ color: '#000000' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={handlePrevMonth} icon={<LeftOutlined />} />
        <span><strong style={{ fontSize: '14px' }}>{currentMonthName} {selectedYear}</strong></span>
        <Button 
          onClick={handleNextMonth} 
          icon={<RightOutlined />} 
          disabled={selectedMonth === currentMonth && selectedYear === currentYear}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
        <h4 style={{ marginTop: 0 }}>Monthly Spend R$ {monthTotal}</h4>
        <span><strong style={{ fontSize: '11px' }}>{currentMonthName} 1 - {daysInMonth}</strong></span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', borderRadius: '8px' }}>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={-270}
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={18}>
              {data.length > 0 && `${data[0].value}%`}
            </text>
          </PieChart>
          <div className="information" style={{ position: 'absolute', right: 20, transform: 'translateY(-50%)' }}>
            <Text strong style={{ fontSize: '24px' }}>{billInformation[0].value}</Text>
            <br />
            <Text style={{ fontSize: '12px', color: '#888' }}>/ 10.000 questions</Text>
          </div>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default PieChartCosts;